import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql, Link } from 'gatsby';
import SEO from '../components/seo';

import TransContainer from '../components/TransContainer';
import Layout from '../components/layout';

const Wrapper = styled.div`
  text-align: center;
  width: 100%;
`;

const Title = styled.h2`
  color: black;
  font-size: 14.5vw;
  line-height: 1;
  margin-bottom: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 22px 15px 0;

  @media (min-width: 800px) {
    font-size: 100px;
    padding: 22px 20px 0;
  }
`;

const UpcomingWrapper = styled.div`
  margin-bottom: 100px;
  padding: 0 15px;

  @media (min-width: 800px) {
    margin-bottom: 120px;
    padding: 0 20px;
  }
`;

const UpcomingText = styled.div`
  font-family: editorial;
  font-size: 40px;
  margin-bottom: 0;
  line-height: 1.2;
  text-align: left;
  
  p {
    margin: 0;
  }

  @media (min-width: 800px) {
    font-size: 80px;
  }
`;

const YearWrapper = styled.section`
  display: block;
  padding: 0 15px;
  margin-bottom: 40px;
  
  @media (min-width: 800px) {
    margin-bottom: 100px;
    padding: 0 20px;
  }
`;

const YearTitle = styled(UpcomingText)`
  margin-bottom: 20px;
`;

const YearItem = styled.article`
  font-family: kormelink;
  font-size: 22px;
  line-height: 1.2;
  text-align: left;

  @media (min-width: 800px) {
    font-size: 24px;
  }
`;

const BigLinksWrapper = styled.div`
  padding: 0 15px;
  margin: 120px 0 140px;
  text-align: center;

  @media (min-width: 800px) {
    margin: 120px 0 120px;
    padding: 0 20px;
  }
`;

const BigLinkBack = styled.div`
  font-family: kormelink;
  letter-spacing: 0.1px;
  font-size: 21px;
  line-height: 26px;
  margin-bottom: 10px;

  @media (min-width: 800px) {
    font-size: 38px;
    line-height: 47px;
    margin-bottom: 20px;
    
    &:hover {
      text-decoration: underline;
    }
  }

  a {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const Calendar = props => {
  const renderName = name => {
    const splitName = name.split('');
    let newName = [];

    splitName.forEach((item, i) => {
      newName.push(<span key={item + i}>{item}</span>);
    });

    return newName;
  };

  return (
    <StaticQuery
      query={graphql`
        query {
          sako {
            calendarPages {
              title
            }
            calendarPageSections(orderBy: createdAt_DESC) {
              title
              entry
            }
            upcomings {
              title
              link {
                html
              }
            }
          }
        }
      `}
      render={data => {
        const { calendarPages, calendarPageSections, upcomings } = data.sako;
        const { title } = calendarPages[0];

        return (
          <Layout showCard={true} showCardMobile={false} transitionStatus={props.transitionStatus} title="Calendar">
            <SEO title='Paweł Sakowicz | Calendar' keywords={[`Paweł Sakowicz`, `choreography`, `dance`]} />
            <Wrapper>
              <Title>{renderName(title.toUpperCase())}</Title>
              <TransContainer>
                <UpcomingWrapper>
                  <UpcomingText as='h2'>{upcomings[0].title}</UpcomingText>

                  {upcomings[0].link.map((item, i) => {
                    return <UpcomingText key={i} dangerouslySetInnerHTML={{ __html: item.html }}></UpcomingText>;
                  })}
                </UpcomingWrapper>

                {calendarPageSections.map((item, i) => {
                  return (
                    <YearWrapper key={i}>
                      <YearTitle as='h3'>{item.title}</YearTitle>
                      {item.entry.map((entry, j) => {
                        return <YearItem key={j}>{entry}</YearItem>;
                      })}
                    </YearWrapper>
                  );
                })}
              </TransContainer>
            </Wrapper>
            <BigLinksWrapper>
              <BigLinkBack>
                <Link to='/'>&#x021A9; Back</Link>
              </BigLinkBack>
            </BigLinksWrapper>
          </Layout>
        );
      }}
    />
  );
};

export default Calendar;
