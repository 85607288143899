import React from 'react';
import { TransitionState } from 'gatsby-plugin-transition-link';
import AnimContainer from './AnimContainer';

const TransContainer = ({ children, transitionStatus }) => {
  return (
    <TransitionState>
      {({ mount }) => {
        return <AnimContainer mount={mount} transitionStatus={transitionStatus} children={children} />;
      }}
    </TransitionState>
  );
};

export default TransContainer;
