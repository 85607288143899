import React from 'react';
import { useSpring, animated } from 'react-spring';

const AnimContainer = ({ mount, children, transitionStatus }) => {
  const animation = useSpring({
    transition: 'opacity 0.1s ease-in-out 0.05s',
    opacity: mount || transitionStatus === 'POP' ? 1 : 0
  });
  return <animated.div style={animation}>{children}</animated.div>;
};

export default AnimContainer;
